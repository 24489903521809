/* Iconfont */
@font-face {
  font-family: "icons";
  src: url("../fonts/icons.eot") format("embedded-opentype"),
    url("../fonts/icons.woff2") format("woff2"),
    url("../fonts/icons.woff") format("woff");
}

i[class^="icon-"]:before,
i[class*=" icon-"]:before {
  font-family: icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-intro:before {
  content: "\f101";
}
.icon-contacts:before {
  content: "\f102";
}
.icon-gallery:before {
  content: "\f103";
}
.icon-nineties:before {
  content: "\f104";
}
.icon-origin:before {
  content: "\f105";
}
.icon-soviet:before {
  content: "\f106";
}
/* Iconfont */

html,
body,
address,
article,
aside,
blockquote,
dd,
dl,
dt,
fieldset,
figure,
form,
h1,
h2,
h3,
h4,
h5,
h6,
li,
nav,
ol,
p,
pre,
r-grid,
table,
tfoot,
ul,
video {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

:root {
  --page-aside-width: 160px;
  --page-header-height: 160px;
}

@import "./page.css";
@import "./navigation.css";
@import "./main.css";
@import "./section.css";
@import "./section-media.css";
@import "./menu.css";
@import "./header.css";
@import "./media.css";
@import "./socials.css";
@import "./logo.css";
@import "./preloader.css";

@import "./section-intro.css";
@import "./section-soviet.css";
@import "./section-gallery.css";
@import "./section-nineties.css";
@import "./section-contacts.css";
@import "./carousel.css";

.hidden {
  opacity: 0;
  pointer-events: none;
}

@media only screen and (max-width: 667px) {
  :root {
    --page-aside-width: 100%;
  }
}

@media only screen and (max-width: 900px) {
  :root {
    --page-header-height: 80px;
    --page-aside-width: 75px !important;
  }
}
