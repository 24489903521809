.navigation {
}

.navigation__social {
  position: absolute;
  bottom: 60px;
  left: 0;
  right: 0;
}

@media only screen and (max-width: 900px) {
  .navigation__social {
    display: none;
  }
}
