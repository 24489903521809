.logo {
  display: flex;
  height: 76px;
  cursor: pointer;
}

.logo__image {
  transition: transform 0.5s ease;
}

.logo__image_small {
  transform: scale(0.75, 0.75);
}

@media only screen and (max-width: 900px) {
  .logo {
    height: 45px;
  }
}
