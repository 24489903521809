.section-media {
  margin-top: 60px;
  margin-bottom: 70px;
  position: relative;
  border-color: inherit;
}

.section-media::before {
  content: "";
  display: block;
  position: absolute;
  left: -20px;
  top: -20px;
  right: -20px;
  bottom: -30px;
  border: 2px solid;
  border-color: inherit;
  z-index: 1;
}

.section-media.poster::before {
  bottom: -20px;
}

.section-media__media {
  display: block;
  width: 100%;
  margin: 0 auto;
}

.section-media__caption {
  max-width: 395px;
  margin: 20px auto 0;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
}

@media only screen and (max-width: 900px) {
  .section-media {
    margin-bottom: 70px;
  }
}

@media only screen and (max-width: 667px) {
  .section-media::before {
    left: -15px;
    right: -15px;
  }

  .section-media__caption {
    max-width: 100%;
  }
}
