.page {
  background-color: #f9f6f3;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 28px;
  padding-left: var(--page-aside-width);
}

.page__header {
  height: var(--page-header-height);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 10;
}

.page__navigation {
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: var(--page-aside-width);
  display: flex;
  flex-direction: column;
  justify-content: center;
  user-select: none;
}

.page__main {
  margin-top: calc(var(--page-header-height) + 20px);
  max-width: calc(100% - var(--page-aside-width) - 30px);
}

@media only screen and (max-width: 900px) {
  .page {
    padding-right: var(--page-aside-width);
  }

  .page__navigation {
    background-color: white;
    width: 50%;
    left: unset;
    right: 0;
    padding-top: var(--page-header-height);
    overflow: auto;
    justify-content: initial;
  }

  .page__navigation:not([data-visibility="visible"]) {
    display: none;
  }

  .page__main {
    max-width: unset;
  }
}

@media only screen and (max-width: 667px) {
  .page {
    padding-left: 0;
    padding-right: 0;
  }

  .page__navigation {
    background-color: #f9f6f3;
    width: 100%;
  }

  .page__main {
    margin-top: var(--page-header-height);
  }
}
