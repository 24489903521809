.media {
  position: relative;
  width: 100%;
  height: 100%;
}

.media__gradient {
  pointer-events: none;
  position: absolute;
  left: -3px; /* strange Safari bug with tiny gap */
  height: 100%;
  width: 50%;
  background: linear-gradient(
    90deg,
    #f9f6f3 0%,
    rgba(249, 246, 243, 0.5) 50%,
    rgba(249, 246, 243, 0) 100%
  );
  z-index: 101;
}

.media__item {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  transition: all 0.5s ease;
}
