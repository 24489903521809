.header {
  display: flex;
  width: 50%;
  height: 100%;
  align-items: center;
  padding-left: 50px;
}

.header__address {
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: right;
  justify-content: center;
  margin-left: auto;

  font-family: Oswald;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  transition: all 0.5s ease;
}

.header__menu {
  display: none;
}

@media only screen and (max-width: 900px) {
  .header {
    width: 100%;
    padding-right: 50px;
    padding: 20px 25px;
  }
  .header__address {
    display: none;
  }
  .header__menu {
    display: flex;
    justify-content: center;
    margin-left: auto;
    height: 24px;
    width: 24px;
    cursor: pointer;
    position: relative;
    border: 0;
    background: none;
    padding: 0;
    -webkit-appearance: none;
  }
  .header__menu img {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }
}

@media only screen and (max-width: 667px) {
  .header {
    background-color: #f9f6f3;
  }
}
