.socials {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 20px;
  justify-content: center;
}

/* @FIXME */
.socials img {
  width: 24px;
  height: 24px;
  display: block;
}

@media only screen and (max-width: 900px) {
  .socials {
    grid-auto-flow: column;
    margin-top: 25px;
  }
}
