.main {
}

.main__content {
  position: relative;
  z-index: 2;
  width: 50%;
  padding: 0 20px;
}

.main__media {
  position: fixed;
  width: 50%;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

@media only screen and (max-width: 900px) {
  .main__content {
    width: 100%;
  }

  .main__media {
    display: none;
  }
}
