.section-contacts {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: black;
  position: relative;
}

.section-contacts a {
  color: black;
  text-decoration: none;
}

.section__shield {
  background-color: #c89b5a;
  padding: 2px 9px;
  color: white;
  border-radius: 2px;
  margin-top: 12px;
  align-self: flex-start;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
}

.section-contacts__social {
  display: none;
}

.section-contacts__mercury {
  position: absolute;
  bottom: 0;
}

.section-contacts__mercury img {
  width: 126px;
  height: 28px;
}

.section__media#mobile_map {
  aspect-ratio: 16 / 9;
  margin-bottom: 50px;
}

@media only screen and (max-width: 900px) {
  .section-contacts__social {
    display: grid;
  }

  .section-contacts__mercury {
    position: unset;
    margin-top: 69px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
  }

  .section__shield {
    align-self: center;
  }
}
