.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style-type: none;
}

.menu__item {
  margin-bottom: 15px;
  width: 50px;
  height: 50px;
  position: relative;
}

.menu__link {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 9px 13px;
  color: white;
  text-decoration: none;
}

.menu__link:focus {
  outline: none;
}

/* @FIXME */
.menu__link:hover i {
  color: white;
  opacity: 1;
}

/* @FIXME */
.menu__link:hover p {
  display: unset !important;
  color: white !important;
  opacity: 1 !important;
}

/* @FIXME */
.menu__link#intro:hover {
  background-color: black;
}
.menu__link#origin:hover {
  background-color: #c89b5a;
}
.menu__link#soviet:hover {
  background-color: #f00000;
}
.menu__link#nineties:hover {
  background-color: #003ca5;
}
.menu__link#gallery:hover {
  background-color: black;
}
.menu__link#contacts:hover {
  background-color: #c89b5a;
}

/* @FIXME */
.menu__link.selected i {
  opacity: 1 !important;
}

/* @FIXME */
.menu__icon.icon-intro {
  color: black;
}
.menu__icon.icon-origin {
  color: #c89b5a;
}
.menu__icon.icon-soviet {
  color: #f00000;
}
.menu__icon.icon-nineties {
  color: #003ca5;
}
.menu__icon.icon-gallery {
  color: black;
}
.menu__icon.icon-contacts {
  color: #c89b5a;
}

/* @FIXME */
.menu__link p {
  margin-left: 7px;
  font-family: Oswald;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.18em;
  text-transform: uppercase;
  opacity: 0.4;
  display: none;
  white-space: nowrap;
}

.menu__icon {
  font-size: 24px;
  cursor: pointer;
  opacity: 0.2;
  display: flex;
}

@media only screen and (max-width: 900px) {
  .menu {
    margin-top: auto;
    margin-bottom: auto;
  }

  .menu__item {
    height: 60px;
    width: 100%;
  }

  .menu__link {
    width: 100%;
  }

  /* @FIXME */
  .menu__link p {
    opacity: 0.4;
    display: block;
    color: black;
    font-size: 16px;
    line-height: 42px;
    margin-left: 16px;
  }

  .menu__icon {
    margin-left: 32px;
  }

  /* @FIXME */
  .menu__link.selected p {
    opacity: 1;
  }

  /* @FIXME */
  .menu__link.selected#intro p {
    color: black;
  }
  .menu__link.selected#origin p {
    color: #c89b5a;
  }
  .menu__link.selected#soviet p {
    color: #f00000;
  }
  .menu__link.selected#nineties p {
    color: #003ca5;
  }
  .menu__link.selected#gallery p {
    color: black;
  }
  .menu__link.selected#contacts p {
    color: #c89b5a;
  }
}
