.carousel {
  width: 100%;
  height: min-content;
  user-select: none;
}

.carousel .slide {
  display: flex;
  align-items: center;
  justify-items: center;
}

.carousel .slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel .controls {
  width: 44px;
  height: 44px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 22px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
}

.carousel .controls:focus {
  outline: none;
}

.carousel .controls.left {
  left: 31px;
  background-image: url(../images/arrow-left.svg);
}

.carousel .controls.right {
  right: 31px;
  background-image: url(../images/arrow-right.svg);
}

.pagination {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#carousel-origin-pagination .swiper-pagination-bullet-active {
  background-color: #c89b5a;
}
#carousel-soviet-pagination .swiper-pagination-bullet-active {
  background-color: #f00000;
}
#carousel-gallery-pagination .swiper-pagination-bullet-active {
  background-color: black;
}

:root {
  --swiper-pagination-bullet-horizontal-gap: 10px;
  --swiper-pagination-bullet-inactive-color: rgba(0, 0, 0, 0.2);
  --swiper-pagination-bullet-width: 10px;
  --swiper-pagination-bullet-height: 10px;
}

@media only screen and (max-width: 669px) {
  .carousel .controls {
    display: none;
  }
}
