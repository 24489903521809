.scroll-disabled {
  overflow-y: hidden !important;
}

.page__loader {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #f9f6f3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  user-select: none;
}

.page__loader > div {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.page__loader img {
  margin-bottom: 75px;
}

.page__loader h1 {
  font-size: 2rem;
}

.page__loader p {
  margin-top: 10px;
  color: black;
  font-size: 1rem;
}
