.section {
  color: black;
  margin-bottom: 85px;
}

.section__media {
  display: none;
  width: 100%;
  object-fit: contain;
}

.section__header {
  display: flex;
  font-family: Oswald;
  font-style: normal;
  font-weight: normal;
  font-size: 64px;
  line-height: 84px;
  text-transform: uppercase;
  margin-bottom: 50px;
}

/* @FIXME */
.section__header i {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-top: 23px;
  font-size: 41px;
}

.section__subheader {
  font-family: Oswald;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 42px;
}

.section__text {
  margin-top: 30px;
  margin-bottom: 30px;
  color: #000;
}

.section__text p {
  margin-top: 13px;
}

.section__text p:first-of-type {
  margin-top: 0;
}

.section__text_highlighted {
  margin-top: 45px;
  margin-bottom: 45px;
  color: inherit;
  border-color: inherit;
  position: relative;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
}

.section__text_highlighted::before {
  content: "";
  display: block;
  position: absolute;
  left: -20px;
  top: -20px;
  right: -20px;
  bottom: -20px;
  border: 2px solid;
  border-color: inherit;
  z-index: 1;
}

.section__break {
  border: none;
  margin-top: 65px;
  margin-bottom: 30px;
}

.section__break::before {
  content: "***";
  text-align: center;
  display: block;
  font-family: Oswald;
  font-style: normal;
  font-weight: normal;
  font-size: 64px;
  line-height: 50px;
  color: rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 900px) {
  .section__media {
    display: block;
  }

  .section__header {
    font-size: 44px;
    line-height: 60px;
    flex-direction: column-reverse;
    margin-bottom: 20px;
  }

  .section__header i {
    font-size: 24px;
    justify-content: flex-start;
  }

  .section__text {
    font-size: 14px;
    line-height: 24px;
    margin-top: 20px;
  }

  .section__text_highlighted {
    margin-top: 40px;
  }

  .section__subheader {
    font-size: 26px;
    line-height: 38px;
  }

  section#contacts .section__subheader {
    text-align: center;
  }

  .section__break {
    margin-top: 20px;
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 667px) {
  .section_type_contacts {
    height: auto;
  }

  .section {
    margin-bottom: 40px;
  }

  .section__text_highlighted::before {
    left: -15px;
    right: -15px;
  }
}
